import { db } from "@/firebase";

const state = {
  currentProgram: null,
  programId: null,
  domainError: null,
  admins: [],
  initialLocale: "en"
};

const actions = {
  async loadAdmins({ commit }) {
    try {
      const querySnapshot = await db.collection("admins").get();
      const admins = [];
      querySnapshot.forEach(function(doc) {
        // user is admin
        admins.push({
          userId: doc.data().userId,
          email: doc.data().email,
          name: doc.data().name,
          programs: doc.data().programs || [],
          organisations: doc.data().organisations || [],
          customerToken: doc.data().customerToken
        });
      });
      commit("setAdmins", admins);
    } catch (error) {
      console.log(error);
    }
  },

  async getProgram({ commit, getters }, payload) {
    //console.log("getProgram");

    let programSnapshot;
    try {
      programSnapshot = await db
        .collection("programs")
        .doc(payload)
        .get();
    } catch (e) {
      commit("setCurrentProgram", null);
      console.log("throw 500; ", e);
    }

    const programData = programSnapshot.data();
    const currentProgram = {
      ...programData,
      createdAt: programData.createdAt.toDate()
    };
    if (!currentProgram) {
      commit("setCurrentProgram", null);
      console.log("throw 404");
    }
    const favicon = document.getElementById("favicon");
    var programFavicon = "favicons/incentable.png";
    if (currentProgram.webTheme.favicon) {
      programFavicon = "favicons/" + currentProgram.webTheme.favicon;
    }
    favicon.setAttribute("href", programFavicon);
    commit("setInitialLocale", currentProgram.translate.locale);
    commit("setCurrentProgram", currentProgram);
  },

  async setProgramId({ commit, dispatch, getters }) {
    // dispatch("setLoading", true);
    // console.log("setProgramId");
    dispatch("setDomainError", null);
    let siteSnapshot;
    let sites = [];
    try {
      siteSnapshot = await db.collection("sites").get();
    } catch (e) {
      siteSnapshot = [];
      console.log("throw 500; ", e);
    }
    siteSnapshot.forEach(doc => {
      sites.push({
        ...doc.data()
      });
    });
    // console.log(sites)
    const host = window.location.host;
    const found = sites.find(el => el.activeDomain === host);

    if (host === "localhost:8080" || host === "localhost:8081") {
      // const id = "dxkKiRMcpF2rd2ikRbv7"; // Demo
      const id = "0eLn49KYPcBzKl1v5CsZ"; // Surface
      // const id = "PzhkOS5QL6WWd1PJwTAc"; // LG
      commit("setProgramId", id);
      await dispatch("getProgram", id);
    } else if (!found) {
      // console.log("not found");
      commit("setProgramId", null);
      dispatch("setDomainError", "nodomain");
      dispatch("logout");
    } else if (found && found.program === "") {
      // console.log("found empty value!!!---");
      commit("setProgramId", null);
      dispatch("setDomainError", "noprogram");
      dispatch("logout");
    } else {
      // console.log("found");
      const id = found.program;
      // console.log("setting id ...", id);
      commit("setProgramId", id);
      await dispatch("getProgram", id);
    }
  },
  setDomainError({ commit }, error) {
    commit("setDomainError", error);
  },
  setCurrentProgram({ commit }, payload) {
    commit("setCurrentProgram", payload);
  },
  setAdmins({ commit }, payload) {
    commit("setAdmins", payload);
  }
};

const mutations = {
  setInitialLocale(state, locale) {
    state.initialLocale = locale;
  },
  setProgramId(state, id) {
    state.programId = id;
  },
  setCurrentProgram(state, program) {
    state.currentProgram = program;
  },
  setDomainError(state, error) {
    state.domainError = error;
  },
  setAdmins(state, payload) {
    state.admins = payload;
  }
};

const getters = {
  initialLocale(state) {
    return state.initialLocale;
  },
  programId(state) {
    return state.programId;
  },
  currentProgram(state) {
    return state.currentProgram;
  },
  domainError(state) {
    return state.domainError;
  },
  admins(state) {
    return state.admins;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
